import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faArrowRightArrowLeft } from '@fortawesome/pro-duotone-svg-icons/faArrowRightArrowLeft';
import { faCommentsQuestionCheck } from '@fortawesome/pro-duotone-svg-icons/faCommentsQuestionCheck';
import { faAperture } from '@fortawesome/pro-regular-svg-icons/faAperture';
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons/faBriefcase';
import { faBriefcaseArrowRight } from '@fortawesome/pro-regular-svg-icons/faBriefcaseArrowRight';
import { faBuildingMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faBuildingMagnifyingGlass';
import { faCalendarRange } from '@fortawesome/pro-regular-svg-icons/faCalendarRange';
import { faChartWaterfall } from '@fortawesome/pro-regular-svg-icons/faChartWaterfall';
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faListTimeline } from '@fortawesome/pro-regular-svg-icons/faListTimeline';
import { faMessageExclamation } from '@fortawesome/pro-regular-svg-icons/faMessageExclamation';
import { faNewspaper } from '@fortawesome/pro-regular-svg-icons/faNewspaper';
import { faObjectExclude } from '@fortawesome/pro-regular-svg-icons/faObjectExclude';
import { faRadar } from '@fortawesome/pro-regular-svg-icons/faRadar';
import { faTable } from '@fortawesome/pro-regular-svg-icons/faTable';
import { faTableColumns } from '@fortawesome/pro-regular-svg-icons/faTableColumns';
import { faTableTree } from '@fortawesome/pro-regular-svg-icons/faTableTree';
import { faUsersBetweenLines } from '@fortawesome/pro-regular-svg-icons/faUsersBetweenLines';
import { faWindow } from '@fortawesome/pro-solid-svg-icons/faWindow';
import { type i18n, type KeysWithoutReturnObjects } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { WidgetType } from 'src/contracts/workspace';
import type { WidgetViewModel } from 'src/store/types';

export interface WidgetGlossaryEntry {
    nameKey: KeysWithoutReturnObjects['json'];
    roles: string[];
    icon: IconDefinition;
}

export type WidgetNameKey = WidgetGlossaryEntry['nameKey'];

export const WidgetGlossary: Record<WidgetType, WidgetGlossaryEntry> = {
    accounts: {
        nameKey: 'widgets::accounts::name',
        roles: ['widgets::accounts'],
        icon: faUsersBetweenLines,
    },
    'aggregated-positions': {
        nameKey: 'widgets::aggregated-positions::name',
        roles: ['widgets::aggregated-positions'],
        icon: faObjectExclude,
    },
    'alpha-lens': {
        nameKey: 'widgets::alpha-lens::name',
        roles: ['widgets::alpha-lens'],
        icon: faAperture,
    },
    alphabot: {
        nameKey: 'widgets::chat::name',
        roles: ['widgets::chat'],
        icon: faCommentsQuestionCheck,
    },
    events: {
        nameKey: 'widgets::events::name',
        roles: ['widgets::events'],
        icon: faCalendarRange,
    },
    executions: {
        nameKey: 'widgets::executions::name',
        roles: ['widgets::executions'],
        icon: faTableTree,
    },
    locates: {
        nameKey: 'widgets::locates::name',
        roles: ['widgets::locates'],
        icon: faRadar,
    },
    messages: {
        nameKey: 'widgets::messages::name',
        roles: ['widgets::messages'],
        icon: faMessageExclamation,
    },
    news: {
        nameKey: 'widgets::news::name',
        roles: ['widgets::news'],
        icon: faNewspaper,
    },
    'order-blotter': {
        nameKey: 'widgets::order-blotter::name',
        roles: ['widgets::order-blotter'],
        icon: faClipboardList,
    },
    'order-entry': {
        nameKey: 'widgets::order-entry::name',
        roles: ['widgets::order-entry'],
        icon: faBriefcaseArrowRight,
    },
    orders: {
        nameKey: 'widgets::orders::name',
        roles: ['widgets::orders'],
        icon: faBriefcase,
    },
    positions: {
        nameKey: 'widgets::positions::name',
        roles: ['widgets::positions'],
        icon: faListTimeline,
    },
    searchalpha: {
        nameKey: 'widgets::searchalpha::name',
        roles: ['widgets::searchalpha', 'widgets::search-alpha'],
        icon: faBuildingMagnifyingGlass,
    },
    scanner: {
        nameKey: 'widgets::scanner::name',
        roles: ['widgets::scanner'],
        icon: faWindow, // Add appropriate icon if available
    },
    screener: {
        nameKey: 'widgets::screener::name',
        roles: ['widgets::screener'],
        icon: faWindow, // Add appropriate icon if available
    },
    'simple-order-entry': {
        nameKey: 'widgets::simple-order-entry::name',
        roles: ['widgets::simple-order-entry'],
        icon: faArrowRightArrowLeft,
    },
    results: {
        nameKey: 'widgets::results::name',
        roles: ['widgets::results'],
        icon: faTable,
    },
    table: {
        nameKey: 'widgets::table::name',
        roles: ['widgets::table'],
        icon: faTable,
    },
    'time-series': {
        nameKey: 'widgets::time-series::name',
        roles: ['widgets::time-series'],
        icon: faChartWaterfall,
    },
    watchlist: {
        nameKey: 'widgets::watchlist::name',
        roles: ['widgets::watchlist'],
        icon: faTableColumns,
    },
    filings: {
        // Add this entry
        nameKey: 'widgets::filings::name',
        roles: ['widgets::filings'],
        icon: faNewspaper, // Use an appropriate icon
    },
};
Object.freeze(WidgetGlossary);

interface WidgetNameDerivable {
    type: WidgetViewModel['type'];
}

export function useWidgetDisplayName({ type }: WidgetNameDerivable): string {
    return useTranslation().t(WidgetGlossary[type].nameKey);
}

export function getWidgetDisplayName(t: i18n['t'], { type }: WidgetNameDerivable): string {
    return t(WidgetGlossary[type].nameKey);
}
